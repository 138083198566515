<template>
    <div class="nvx-app-container">
        <nvx-button-view>

            <template v-slot:content>
                <router-link to="/account">
                    <chevron :iconDirection="'left'" />
                </router-link>
                <h2 class="sdc-title"> {{ $t("profil_update_title") }} </h2>

                <nvx-list>
                    <nvx-section>
                        <p> {{ $t("firstname") | capitalize }} </p>
                        <input v-model="name" @input="checkFirstName" type="text" class="sdc-field"
                            :placeholder="getUserSetting.firstname" />
                    </nvx-section>

                    <nvx-section>
                        <p> {{ $t("lastname") | capitalize }} </p>
                        <input v-model="lastname" @input="checkLastName" type="text" class="sdc-field"
                            :placeholder="getUserSetting.lastname" />
                    </nvx-section>

                </nvx-list>
            </template>

            <template v-slot:buttons>
                <button class="sdc-button" :disabled="!isAbleToSave" block @click="submitProfilSettings()"
                    variant="primary">
                    {{ $t("Save") }}
                </button>
            </template>
            
        </nvx-button-view>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

import Chevron from '../svg/chevron.vue';
import NvxSection from "@/components/ui/NvxSection.vue";
import NvxList from "@/components/ui/NvxList.vue";
import NvxButtonView from "@/components/ui/NvxButtonView.vue";

export default {
    components: {
        Chevron,
        NvxSection,
        NvxList,
        NvxButtonView
    },
    name: "AccountProfilUpdate",
    data() {
        return {
            lastname: null,
            name: null,
            birthday: null,
            today: null,
            isFirstNameInvalid: false,
            isLastNameInvalid: false,
            isBirthdayInvalid: false
        };
    },
    computed: {
        ...mapGetters("users", ["getUserSetting"]),
        formattedBirthday() {
            if (this.getUserSetting && this.getUserSetting.birthday) {
                let dateParts = this.getUserSetting.birthday.split("-");
                return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
            }
            return null;
        },
        isAbleToSave() {
            // Name and Last Name are mandatory, birthday is optional
            if (this.name && this.lastname) {
                // If either firstname or lastname is different, we can save
                if (this.name != this.getUserSetting.firstname) {
                    return true
                }
                if (this.lastname != this.getUserSetting.lastname) {
                    return true
                }
                // if both firstname and lastname are the same, we can save only if birthdate is different
                return this.isBirthdayValid && this.birthday != this.getUserSetting.birthday

            }
            else {
                return false
            }

        },
        // isFirstNameValid() {
        //     return this.name && this.getUserSetting && this.name.toLowerCase() !== this.getUserSetting.firstname.toLowerCase();
        // },
        // isLastNameValid() {
        //     return this.lastname && this.getUserSetting && this.lastname.toLowerCase() !== this.getUserSetting.lastname.toLowerCase();
        // },
        isBirthdayValid() {
            return !this.isBirthdayInvalid //this.birthday && this.getUserSetting && this.birthday !== this.getUserSetting.birthday;
        },
    },
    methods: {
        ...mapActions("users", ["updateUserProfile", "fetchUserData", "fetchUserSetting"]),
        async submitProfilSettings() {
            try {
                let isDataChanged = false;
                let userData = {};

                if (this.isAbleToSave) { // name is always prefilled by getUsersetting.firstname.
                    userData.firstname = this.name;
                    isDataChanged = true;
                }
                if (this.isAbleToSave) { // lastname is always prefilled by getUserSetting.lastname.
                    userData.lastname = this.lastname;
                    isDataChanged = true;
                }
                if (this.isBirthdayValid) {
                    userData.birthday = this.birthday;
                    isDataChanged = true;
                }

                if (isDataChanged) {
                    let response = await this.updateUserProfile(userData);

                    if (response.status === 200) {
                        await this.fetchUserData();
                        await this.fetchUserSetting();
                    } else {
                        console.log('Error during the update');
                    }
                } else {
                    console.log('Data has not changed, no server update required');
                }
            } catch (error) {
                console.error("Error in submitProfilSettings: ", error);
            } finally {
                window.location.href = '/account';
            }
        },
        checkFirstName() {
            // this.isFirstNameInvalid = this.name && this.name.toLowerCase() === this.getUserSetting.firstname.toLowerCase();
        },
        checkLastName() {
            // this.isLastNameInvalid = this.lastname && this.lastname.toLowerCase() === this.getUserSetting.lastname.toLowerCase();
        },
        checkBirthday() {
            this.isBirthdayInvalid = !(this.birthday && this.birthday < this.today)
        },
        formatDate(dateString) {
            var d = new Date(dateString);
            var month = '' + (d.getMonth() + 1);
            if (month.length < 2) {
                month = '0' + month;
            }
            var day = '' + (d.getDate() + 1);
            if (day.length < 2) {
                day = '0' + day;
            }
            let year = d.getFullYear();
            return [year, month, day].join('-');
        }
    },
    async mounted() {
        await this.fetchUserData();
        await this.fetchUserSetting();

        window.zE("webWidget", "hide");
        // It is easier to prefill name and lastname directly with current value.
        if (this.getUserSetting.firstname) {
            this.name = this.getUserSetting.firstname
        }

        if (this.getUserSetting.lastname) {
            this.lastname = this.getUserSetting.lastname
        }

        this.today = moment().format("YYYY-MM-DD")
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";
@import "@/assets/scss/appmenus/native.scss";
</style>